import { AccountInfo } from '../../ui-components/pages/account-info-types'
import { AccountInfoActionTypes, ACCOUNT_INFO_CLEARED, ACCOUNT_INFO_UPDATED } from './types'

export function updateAccountInfo(value: AccountInfo): AccountInfoActionTypes {
  return {
    type: ACCOUNT_INFO_UPDATED,
    payload: value
  }
}

export function clearAccountInfo(): AccountInfoActionTypes {
  return {
    type: ACCOUNT_INFO_CLEARED,
  }
}
