import * as React from 'react'
import { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { clearCompanyInfo, updateCompanyInfo } from '../../store/company-info/actions'
import EmailInput from '../molecules/email-input'
import PostalCodeInput from '../molecules/postal-code-input'
import TelNoInput from '../molecules/tel-no-input'
import TextInput from '../molecules/text-input'
import Button from '../molecules/button'
import {
  BasicInfo,
  CompanyInfo,
  RepresentativeInfoToUser,
  RepresentativeInfoFromUser
} from './company-info-types'

const $ = require('jquery')

interface CompanyInfoFormProps {
  companyInfo: CompanyInfo
  updateCompanyInfo: (value: CompanyInfo) => void
  clearCompanyInfo: () => void
  onValidated?: (success: boolean) => void
  readOnly?: boolean
}

function CompanyInfoForm(props: CompanyInfoFormProps) {
  const { clearCompanyInfo, onValidated } = props
  const { basic, fromUser, toUser } = props.companyInfo

  const companyInfoRef = React.useRef(props.companyInfo)
  companyInfoRef.current = props.companyInfo

  // clear company info on component-will-unmount
  useEffect(() => {
    var form = $('#company-info-form').parsley()
    if (onValidated) {
      form.on('field:validated', () => {
        onValidated(form.isValid())
      })
    }
    return () => {
      clearCompanyInfo()
    }
  }, [clearCompanyInfo, onValidated ])

  const setCompanyInfo = useCallback(
    <K extends keyof CompanyInfo>(key: K, value: Partial<CompanyInfo[K]>) => {
      props.updateCompanyInfo({
        ...companyInfoRef.current,
        [key]: {
          ...companyInfoRef.current[key],
          ...value
        }
      })
    },
    [props]
  )

  const setFromUserAll = useCallback(
    (values: RepresentativeInfoFromUser) => {
      props.updateCompanyInfo({
        ...props.companyInfo,
        fromUser: values
      })
    },
    [props]
  )

  const setBasic = useCallback(
    (key: keyof BasicInfo, value: string) => {
      setCompanyInfo('basic', {
        [key]: value
      })
    },
    [setCompanyInfo]
  )

  const setToUser = useCallback(
    (key: keyof RepresentativeInfoToUser, value: string) => {
      setCompanyInfo('toUser', {
        [key]: value
      })
    },
    [setCompanyInfo]
  )

  const setFromUser = useCallback(
    (key: keyof RepresentativeInfoFromUser, value: string) => {
      setCompanyInfo('fromUser', {
        [key]: value
      })
    },
    [setCompanyInfo]
  )

  const copyToUser = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const fromUserValues = {
      name: toUser.name,
      nameKana: toUser.nameKana,
      department: toUser.department,
      position: toUser.position,
      telNo: toUser.telNo,
      email: toUser.email,
      postalCodeUpper: toUser.postalCodeUpper,
      postalCodeLower: toUser.postalCodeLower,
      prefecture: toUser.prefecture,
      cityTown: toUser.cityTown,
      buildingNo: toUser.buildingNo,
      buildingName: toUser.buildingName
    }
    setFromUserAll(fromUserValues)
  }

  const validateTime = (): boolean => {
    if (toUser.openStartTime === undefined || toUser.openEndTime === undefined) {
      return true
    }

    const regex: string = '^([01][0-9]|2[0-3]):[0-5][0-9]$'
    if (toUser.openStartTime.match(regex) !== null && toUser.openEndTime.match(regex) !== null) {
      const startTime = toUser.openStartTime.split(':')
      const endTime = toUser.openEndTime.split(':')

      if (startTime[0] > endTime[0]) {
        return false
      }

      if (startTime[0] === endTime[0]) {
        if (startTime[1] > endTime[1]) {
          return false
        }
      }

      return true
    }
    return true
  }

  return (
    <form id="company-info-form">
      <fieldset>
        <legend>基本情報</legend>
        <TextInput
          label="企業名"
          placeHolder="企業名を入力してください"
          message="入力が無効です"
          value={basic.companyName}
          onChange={(value) => setBasic('companyName', value)}
        />

        <TextInput
          label="企業名（カナ）"
          placeHolder="企業名（カナ）を全角で入力してください"
          message="入力が無効です"
          validator="zenkaku_katakana"
          value={basic.companyNameKana}
          onChange={(value) => setBasic('companyNameKana', value)}
        />

        <TextInput
          label="企業名略称"
          placeHolder="企業名略称を入力してください"
          message="入力が無効です"
          value={basic.companyNameShort}
          onChange={(value) => setBasic('companyNameShort', value)}
        />

        <TextInput
          label="代表者名"
          placeHolder="代表者名を入力してください"
          message="入力が無効です"
          value={basic.representativeName}
          onChange={(value) => setBasic('representativeName', value)}
        />

        <TextInput
          label="代表者名（カナ）"
          placeHolder="代表者名（カナ）を全角で入力してください"
          message="入力が無効です"
          validator="zenkaku_katakana"
          value={basic.representativeNameKana}
          onChange={(value) => setBasic('representativeNameKana', value)}
        />

        <TextInput
          label="代表者所属部署"
          placeHolder="代表者所属部署を入力してください"
          message="入力が無効です"
          value={basic.representativeDepartment}
          onChange={(value) => setBasic('representativeDepartment', value)}
        />

        <TextInput
          label="代表者役職"
          placeHolder="代表者役職を入力してください"
          message="入力が無効です"
          value={basic.representativePosition}
          onChange={(value) => setBasic('representativePosition', value)}
        />

        <TelNoInput
          label="代表電話番号"
          placeHolder="電話番号を入力してください。例：123-4567-8901"
          message="入力が無効です"
          value={basic.representativeTelNo}
          onChange={(value) => setBasic('representativeTelNo', value)}
        />

        <legend>住所（本店所在地）</legend>

        <PostalCodeInput
          label="郵便番号"
          placeHolder="郵便番号を入力してください。例：123-4567"
          message="入力が無効です"
          codeUpper={basic.postalCodeUpper}
          codeLower={basic.postalCodeLower}
          onChange={(value) =>
            setCompanyInfo('basic', {
              postalCodeUpper: value.codeUpper,
              postalCodeLower: value.codeLower
            })
          }
        />

        <TextInput
          label="都道府県"
          placeHolder="都道府県を入力してください"
          message="入力が無効です"
          value={basic.prefecture}
          onChange={(value) => setBasic('prefecture', value)}
        />

        <TextInput
          label="都道府県（カナ）"
          placeHolder="都道府県（カナ）を全角で入力してください"
          message="入力が無効です"
          validator="zenkaku_katakana"
          value={basic.prefectureKana}
          onChange={(value) => setBasic('prefectureKana', value)}
        />

        <TextInput
          label="市区"
          placeHolder="市区を入力してください"
          message="入力が無効です"
          value={basic.city}
          onChange={(value) => setBasic('city', value)}
        />

        <TextInput
          label="市区（カナ）"
          placeHolder="市区（カナ）を全角で入力してください"
          message="入力が無効です"
          validator="zenkaku_katakana"
          value={basic.cityKana}
          onChange={(value) => setBasic('cityKana', value)}
        />

        <TextInput
          label="町村"
          placeHolder="町村を入力してください"
          message="入力が無効です"
          value={basic.town}
          onChange={(value) => setBasic('town', value)}
        />

        <TextInput
          label="町村（カナ）"
          placeHolder="町村（カナ）を全角で入力してください"
          message="入力が無効です"
          validator="zenkaku_katakana"
          value={basic.townKana}
          onChange={(value) => setBasic('townKana', value)}
        />

        <TextInput
          label="丁目番地"
          placeHolder="丁目番地を入力してください"
          message="入力が無効です"
          value={basic.buildingNo}
          onChange={(value) => setBasic('buildingNo', value)}
        />

        <TextInput
          label="丁目番地（カナ）"
          placeHolder="丁目番地（カナ）を全角で入力してください"
          message="入力が無効です"
          validator="zenkaku_katakana"
          value={basic.buildingNoKana}
          onChange={(value) => setBasic('buildingNoKana', value)}
        />

        <TextInput
          label="ビル・フロア名称"
          placeHolder="ビル・フロア名称を入力してください"
          message="入力が無効です"
          value={basic.buildingName}
          onChange={(value) => setBasic('buildingName', value)}
        />

        <TextInput
          label="ビル・フロア名称（カナ）"
          placeHolder="ビル・フロア名称（カナ）を全角で入力してください"
          message="入力が無効です"
          validator="zenkaku_katakana"
          value={basic.buildingNameKana}
          onChange={(value) => setBasic('buildingNameKana', value)}
        />
      </fieldset>

      <fieldset>
        <legend>担当者情報（アクリートからの連絡先）</legend>

        <TextInput
          label="担当者名"
          placeHolder="担当者名を入力してください"
          message="入力が無効です"
          value={toUser.name}
          onChange={(value) => setToUser('name', value)}
        />

        <TextInput
          label="担当者名（カナ）"
          placeHolder="担当者名（カナ）を全角で入力してください"
          message="入力が無効です"
          validator="zenkaku_katakana"
          value={toUser.nameKana}
          onChange={(value) => setToUser('nameKana', value)}
        />

        <TextInput
          label="担当者所属部署"
          placeHolder="担当者所属部署を入力してください"
          message="入力が無効です"
          value={toUser.department}
          onChange={(value) => setToUser('department', value)}
        />

        <TextInput
          label="担当者役職"
          placeHolder="担当者役職を入力してください"
          message="入力が無効です"
          value={toUser.position}
          onChange={(value) => setToUser('position', value)}
        />

        <TelNoInput
          label="担当者電話番号"
          placeHolder="電話番号を入力してください。例：123-4567-8901"
          message="入力が無効です"
          value={toUser.telNo}
          onChange={(value) => setToUser('telNo', value)}
        />

        <div className="form-group row m-b-15">
          <label className="col-form-label col-md-3">問い合わせ受付時間帯</label>
          <div className="col-sm-9 form-inline" id="time-errors">
            <input
              type="text"
              className="form-control m-b-5"
              placeholder="00:00"
              data-parsley-type="time"
              data-parsley-trigger="input"
              data-parsley-validation-threshold="0"
              data-parsley-error-message="有効な時間帯・形式で入力してください"
              data-parsley-errors-container="#time-errors"
              value={toUser.openStartTime}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setToUser('openStartTime', event.target.value)
              }
            />
            <span className="m-5">から</span>
            <input
              type="text"
              className="form-control m-b-5"
              placeholder="00:00"
              data-parsley-type="time"
              data-parsley-trigger="input"
              data-parsley-validation-threshold="0"
              data-parsley-error-message="有効な時間帯・形式で入力してください"
              data-parsley-errors-container="#time-errors"
              value={toUser.openEndTime}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setToUser('openEndTime', event.target.value)
              }
            />
            <span className="m-5">まで</span>
            {!validateTime() && (
              <ul className="parsley-errors-list filled" aria-hidden="false">
                <li className="parsley-custom-error-message">開始時間より前の終了時間は無効です</li>
              </ul>
            )}
          </div>
        </div>

        <EmailInput
          label="担当者メールアドレス"
          placeHolder="担当者メールアドレスを入力してください"
          message="入力が無効です"
          value={toUser.email}
          onChange={(value) => setToUser('email', value)}
        />

        <legend>住所（担当者所属部署）</legend>

        <PostalCodeInput
          label="郵便番号"
          placeHolder="郵便番号を入力してください。例：123-4567"
          message="入力が無効です"
          codeUpper={toUser.postalCodeUpper}
          codeLower={toUser.postalCodeLower}
          onChange={(value) =>
            setCompanyInfo('toUser', {
              postalCodeUpper: value.codeUpper,
              postalCodeLower: value.codeLower
            })
          }
        />

        <TextInput
          label="都道府県"
          placeHolder="都道府県を入力してください"
          message="入力が無効です"
          value={toUser.prefecture}
          onChange={(value) => setToUser('prefecture', value)}
        />

        <TextInput
          label="市区町村"
          placeHolder="市区町村を入力してください"
          message="入力が無効です"
          value={toUser.cityTown}
          onChange={(value) => setToUser('cityTown', value)}
        />

        <TextInput
          label="丁目番地"
          placeHolder="丁目番地を入力してください"
          message="入力が無効です"
          value={toUser.buildingNo}
          onChange={(value) => setToUser('buildingNo', value)}
        />

        <TextInput
          label="ビル・フロア名称"
          placeHolder="ビル・フロア名称を入力してください"
          message="入力が無効です"
          value={toUser.buildingName}
          onChange={(value) => setToUser('buildingName', value)}
        />
      </fieldset>

      <fieldset>
        <legend>担当者情報（ユーザーからの連絡先）</legend>

        <Button label="アクリートからの連絡先を使用する" onClick={copyToUser} />

        <TextInput
          label="担当者名"
          placeHolder="担当者名を入力してください"
          message="入力が無効です"
          value={fromUser.name}
          onChange={(value) => setFromUser('name', value)}
        />

        <TextInput
          label="担当者名（カナ）"
          placeHolder="担当者名（カナ）を全角で入力してください"
          message="入力が無効です"
          validator="zenkaku_katakana"
          value={fromUser.nameKana}
          onChange={(value) => setFromUser('nameKana', value)}
        />

        <TextInput
          label="担当者所属部署"
          placeHolder="担当者所属部署を入力してください"
          message="入力が無効です"
          value={fromUser.department}
          onChange={(value) => setFromUser('department', value)}
        />

        <TextInput
          label="担当者役職"
          placeHolder="担当者役職を入力してください"
          message="入力が無効です"
          value={fromUser.position}
          onChange={(value) => setFromUser('position', value)}
        />

        <TelNoInput
          label="担当者電話番号"
          placeHolder="電話番号を入力してください。例：123-4567-8901"
          message="入力が無効です"
          value={fromUser.telNo}
          onChange={(value) => setFromUser('telNo', value)}
        />

        <EmailInput
          label="担当者メールアドレス"
          placeHolder="担当者メールアドレスを入力してください"
          message="入力が無効です"
          value={fromUser.email}
          onChange={(value) => setFromUser('email', value)}
        />

        <legend>住所（担当者所属部署）</legend>

        <PostalCodeInput
          label="郵便番号"
          placeHolder="郵便番号を入力してください。例：123-4567"
          message="入力が無効です"
          codeUpper={fromUser.postalCodeUpper}
          codeLower={fromUser.postalCodeLower}
          onChange={(value) =>
            setCompanyInfo('fromUser', {
              postalCodeUpper: value.codeUpper,
              postalCodeLower: value.codeLower
            })
          }
        />

        <TextInput
          label="都道府県"
          placeHolder="都道府県を入力してください"
          message="入力が無効です"
          value={fromUser.prefecture}
          onChange={(value) => setFromUser('prefecture', value)}
        />

        <TextInput
          label="市区町村"
          placeHolder="市区町村を入力してください"
          message="入力が無効です"
          value={fromUser.cityTown}
          onChange={(value) => setFromUser('cityTown', value)}
        />

        <TextInput
          label="丁目番地"
          placeHolder="丁目番地を入力してください"
          message="入力が無効です"
          value={fromUser.buildingNo}
          onChange={(value) => setFromUser('buildingNo', value)}
        />

        <TextInput
          label="ビル・フロア名称"
          placeHolder="ビル・フロア名称を入力してください"
          message="入力が無効です"
          value={fromUser.buildingName}
          onChange={(value) => setFromUser('buildingName', value)}
        />
      </fieldset>
    </form>
  )
}

function mapStateToProps(state: any) {
  const { companyInfo } = state
  return { companyInfo }
}

export default connect(mapStateToProps, { updateCompanyInfo, clearCompanyInfo })(CompanyInfoForm)
