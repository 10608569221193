import { SubmissionFiles } from '../../ui-components/pages/submission-files-types'
import { SubmissionFilesActionTypes, SUBMISSION_FILES_CLEARED, SUBMISSION_FILES_UPDATED } from './types'

export function updateSubmissionFiles(value: SubmissionFiles): SubmissionFilesActionTypes {
  return {
    type: SUBMISSION_FILES_UPDATED,
    payload: value
  }
}

export function clearSubmissionFiles(): SubmissionFilesActionTypes {
  return {
    type: SUBMISSION_FILES_CLEARED,
  }
}
