import { CompanyInfo } from '../../ui-components/pages/company-info-types'
import { CompanyInfoActionTypes, COMPANY_INFO_CLEARED, COMPANY_INFO_UPDATED } from './types'

export function updateCompanyInfo(value: CompanyInfo): CompanyInfoActionTypes {
  return {
    type: COMPANY_INFO_UPDATED,
    payload: value
  }
}

export function clearCompanyInfo(): CompanyInfoActionTypes {
  return {
    type: COMPANY_INFO_CLEARED,
  }
}
