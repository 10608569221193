const parsleyjs = require('parsleyjs')

// zenkaku
// zenkaku katakana
// telephone number (hankaku number and “-”)
// email
// hankaku English string
// <textarea> anything except hankaku Japanese
// latitude number (eg. 34 or 34.234)
// longitude number (eg. 34 or 34.234)
// URL
// number
// one or more IP address
// combo: select from 「車　すべて　docomo　ニュース・情報　学ぶ・働く　エンタメ　ヘルス・ビューティ　ブラント・ファッション　ショッピング　旅行・予約　グルメ・フード・レストラン　お金・銀行・電子マネー　公共・ライフライン　交通・宅配」If not in list, allow text input.

export type Validator =
  | 'zenkaku'
  | 'zenkaku_katakana'
  | 'hankaku_english'
  | 'ip_addresses'
  | 'non_hankaku_japanese'
  | 'url'
  | 'time'
  | 'postal_code'
  | 'tel_no'

const CHARS = {
  // Ref: http://www.rikai.com/library/kanjitables/kanji_codes.unicode.shtml
  // https://stackoverflow.com/a/53807563
  hiragana: '\u3041-\u3096',
  katakana: '\u30A0-\u30FF',
  kanji: '\u3400-\u4DB5\u4E00-\u9FCB\uF900-\uFA6A',
  kanjiRadicals: '\u2E80-\u2FD5',
  katakanaAndPunctuationHalfWidth: '\uFF5F-\uFF9F',
  japaneseSymbolsAndPunctuation: '\u3000-\u303F',
  miscellaneousJapaneseSymbolsAndCharacters: '\u31F0-\u31FF\u3220-\u3243\u3280-\u337F',
  alphanumericAndPunctuationFullWidth: '\uFF01-\uFF5E'
}

export const addZenkakuValidatorToParsley = () => {
  // update existing 'type' validator in parsleyjs
  const originalTypeValidator = parsleyjs._validatorRegistry.validators.type
  parsleyjs.updateValidator(
    'type',
    {
      ...originalTypeValidator,
      validateString: function (value: string, requirement: string) {
        switch (requirement) {
          case 'zenkaku':
            const zenkaku = [
              CHARS.hiragana,
              CHARS.katakana,
              CHARS.kanji,
              CHARS.kanjiRadicals,
              // CHARS.katakanaAndPunctuationHalfWidth, skip this!!!
              CHARS.japaneseSymbolsAndPunctuation,
              CHARS.miscellaneousJapaneseSymbolsAndCharacters,
              CHARS.alphanumericAndPunctuationFullWidth
            ]
            const zenkakuRegex = new RegExp(`^[${zenkaku.join('')}]*$`, 'g')
            return zenkakuRegex.test(value)

          case 'zenkaku_katakana':
            const zenkakuKatakana = [CHARS.katakana]
            const zenkakuKatakanaRegex = new RegExp(`^[${zenkakuKatakana.join('')}０-９]*$`, 'g')
            return zenkakuKatakanaRegex.test(value)

          case 'hankaku_english':
            // eslint-disable-next-line
            const hankakuEnglishRegex = new RegExp('^[0-9A-Za-z!-~\r\n|\n|\r ]*$', 'g')
            return hankakuEnglishRegex.test(value)

          case 'ip_addresses':
            const ipAddressRegex = new RegExp('^[0-9.]*$', 'g')
            return ipAddressRegex.test(value)

          case 'non_hankaku_japanese':
            const nonHankaku = ['^', CHARS.katakanaAndPunctuationHalfWidth]
            const nonHankakuRegex = new RegExp(`^[${nonHankaku.join('')}]*$`)
            return nonHankakuRegex.test(value)

          case 'time':
            const timeRegex = new RegExp('^([01][0-9]|2[0-3]):[0-5][0-9]$', 'g')
            return timeRegex.test(value)

          case 'postal_code':
            const postalCodeRegex = new RegExp('^[0-9]{3}-[0-9]{4}$', 'g')
            return postalCodeRegex.test(value)

          case 'tel_no':
            const telNoRegex = new RegExp('^[0-9]{2,3}-[0-9]{4}-[0-9]{4}$', 'g')
            return telNoRegex.test(value)

          default:
            // fallback to original validator
            return originalTypeValidator.validateString(value, requirement)
        }
      }
    },
    1
  )
}
