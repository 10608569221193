import { DemoAccount } from '../../ui-components/pages/demo-account-types'
import { DemoAccountActionTypes, DEMO_ACCOUNT_CLEARED, DEMO_ACCOUNT_UPDATED } from './types'

export function updateDemoAccount(value: DemoAccount): DemoAccountActionTypes {
  return {
    type: DEMO_ACCOUNT_UPDATED,
    payload: value
  }
}

export function clearDemoAccount(): DemoAccountActionTypes {
  return {
    type: DEMO_ACCOUNT_CLEARED
  }
}
