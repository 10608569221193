import * as React from 'react'

interface NumberInputProps {
  label: string
  placeHolder: string
  message: string
  min?: number;
  max?: number;
  value: number | string
  onChange: (value?: number) => void
}

function NumberInput(props: NumberInputProps) {
  const { label, placeHolder, message, min, max, value, onChange } = props
  const [inputValue, setInputValue] = React.useState(value)

  const inputValueRef = React.useRef<string | number>(inputValue)
  inputValueRef.current = inputValue

  React.useEffect(() => {
    if (toFloat(value) !== toFloat(inputValueRef.current)) {
      setInputValue(value)
    }
  }, [value, setInputValue])

  const handleChange = React.useCallback((event: any) => {
    let newValueStr: string = event.target.value
    setInputValue(newValueStr)
    if (newValueStr) {
      const newValue = toFloat(newValueStr)
      if (toFloat(value) !== newValue) {
        onChange(newValue)
      }
    } else {
      onChange(undefined)
    }
  }, [value, onChange])

  return (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-3">{label}</label>
      <div className="col-md-9">
        <input
          type="number"
          className="form-control m-b-5"
          placeholder={placeHolder}
          data-parsley-type="number"
          data-parsley-trigger="input"
          data-parsley-validation-threshold="0"
          data-parsley-error-message={message}
          data-parsley-min={min}
          data-parsley-max={max}
          value={inputValue || ''}
          onChange={handleChange}
        />
        {/* <small className="f-s-12 text-grey-darker">{message}</small> */}
      </div>
    </div>
  )
}

const toFloat = (input: string | number): number => {
  if (input && typeof input === 'string') {
    return parseFloat(input)
  } else {
    return input as number
  }
}

export default NumberInput
