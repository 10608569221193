import * as React from 'react'
import { Validator } from '../../ui-helpers/validators'

interface TextInputProps {
  label: string
  placeHolder: string
  message: string
  rows?: number;
  validator?: Validator
  value: string
  onChange: (value: string) => void
}

function TextInput(props: TextInputProps) {
  const { label, placeHolder, message, rows, validator, value, onChange } = props
  return (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-3">{label}</label>
      <div className="col-md-9">
        {
          (rows && rows > 1)
          ?
          <textarea
            // type="text"
            className="form-control m-b-5"
            placeholder={placeHolder}
            rows={rows}
            data-parsley-type={validator}
            data-parsley-trigger="input"
            data-parsley-validation-threshold="0"
            data-parsley-error-message={message}
            value={value || ''}
            onChange={(event) => onChange(event.target.value)}
          />
          :
          <input
            type="text"
            className="form-control m-b-5"
            placeholder={placeHolder}
            data-parsley-type={validator}
            data-parsley-trigger="input"
            data-parsley-validation-threshold="0"
            data-parsley-error-message={message}
            value={value || ''}
            onChange={(event) => onChange(event.target.value)}
          />
        }
        {/* <small className="f-s-12 text-grey-darker">{message}</small> */}
      </div>
    </div>
  )
}

export default TextInput
