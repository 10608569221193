import * as React from 'react'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import 'moment/locale/ja'

interface TextInputProps {
  label: string
  placeHolder: string
  message: string
  dateFormat?: string;
  value: string
  onChange: (value: string) => void
}

function DateInput(props: TextInputProps) {
  const { label, placeHolder, dateFormat, value, onChange } = props
  return (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-3">{label}</label>
      <div className="col-md-9">
      <Datetime
        // className="form-control m-b-5"
        // placeholder={placeHolder}
        inputProps={{
          placeholder: placeHolder,
        }}
        dateFormat={dateFormat || 'YYYY-MM-DD'}
        timeFormat={false}
        closeOnSelect={true}
        locale="ja"
        value={value || ''}
        onChange={(value) => {
          if (typeof value === 'string') {
            onChange(value);
          } else {
            onChange(value.format(dateFormat || 'YYYY-MM-DD'))
          }
        }}
      />
      {/* <small className="f-s-12 text-grey-darker">{message}</small> */}
      </div>
    </div>
  )
}

export default DateInput
