import * as React from 'react'
import { randomString } from '../../ui-helpers/utils'

interface RadioInputProps {
  label: string
  placeHolder: string
  message: string
  value: boolean
  onChange: (value: boolean) => void
}

function RadioInput(props: RadioInputProps) {
  const { label, value, onChange } = props
  const [id] = React.useState(randomString(10))
  
  return (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-3">{label}</label>
      <div className="col-md-9">
        <input type="radio" id={`${id}-yes`} value="yes" checked={value} onChange={(event) => onChange(event.target.value === 'yes')} />
        <label htmlFor={`${id}-yes`} style={{marginLeft: 2}}>有</label>
        <input type="radio" id={`${id}-no`} value="no" checked={!value} onChange={(event) => onChange(event.target.value === 'yes')} style={{marginLeft: 10}} />
        <label htmlFor={`${id}-no`} style={{marginLeft: 2}}>無</label>
      </div>
    </div>
  )
}

export default RadioInput
