import { CompanyInfo } from '../../ui-components/pages/company-info-types'

export const COMPANY_INFO_UPDATED = 'companyInfo/Updated'
export const COMPANY_INFO_CLEARED = 'companyInfo/Cleared'

export type CompanyInfoState = CompanyInfo

type CompanyInfoUpdatedAction = {
  type: typeof COMPANY_INFO_UPDATED
  payload: CompanyInfo
}

type CompanyInfoClearedAction = {
  type: typeof COMPANY_INFO_CLEARED
}

export type CompanyInfoActionTypes = CompanyInfoUpdatedAction | CompanyInfoClearedAction
