import * as React from 'react'

interface TelNoProps {
  label: string
  placeHolder: string
  message: string
  value: string
  onChange: (value: string) => void
}

function TelNoInput(props: TelNoProps) {
  const { label, placeHolder, message, value, onChange } = props

  const handleOnChange = React.useCallback((value: string) => {
    const regex = /^[0-9-]*$/
    if (regex.test(value)) {
      onChange(value)
    }
  }, [onChange]);

  return (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-3">{label}</label>
      <div className="col-md-9">
        <input
          // mask={mask}
          className="form-control"
          placeholder={placeHolder}
          data-parsley-type="tel_no"
          data-parsley-trigger="input"
          data-parsley-validation-threshold="0"
          data-parsley-error-message={message}
          value={value || ''}
          onChange={(event) => handleOnChange(event.target.value)}
        />
        {/* <small className="f-s-12 text-grey-darker">{message}</small> */}
      </div>
    </div>
  )
}

export default TelNoInput
