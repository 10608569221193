import {
  CompanyInfoActionTypes,
  CompanyInfoState,
  COMPANY_INFO_CLEARED,
  COMPANY_INFO_UPDATED
} from './types'

const initialState: CompanyInfoState = {
  basic: {
    companyName: '',
    companyNameKana: '',
    companyNameShort: '',
    representativeName: '',
    representativeNameKana: '',
    representativeDepartment: '',
    representativePosition: '',
    representativeTelNo: '',
    postalCodeUpper: '',
    postalCodeLower: '',
    prefecture: '',
    prefectureKana: '',
    city: '',
    cityKana: '',
    town: '',
    townKana: '',
    buildingNo: '',
    buildingNoKana: '',
    buildingName: '',
    buildingNameKana: ''
  },
  toUser: {
    name: '',
    nameKana: '',
    department: '',
    position: '',
    telNo: '',
    openStartTime: '',
    openEndTime: '',
    email: '',
    postalCodeUpper: '',
    postalCodeLower: '',
    prefecture: '',
    cityTown: '',
    buildingNo: '',
    buildingName: ''
  },
  fromUser: {
    name: '',
    nameKana: '',
    department: '',
    position: '',
    telNo: '',
    email: '',
    postalCodeUpper: '',
    postalCodeLower: '',
    prefecture: '',
    cityTown: '',
    buildingNo: '',
    buildingName: ''
  }
}

export function companyInfoReducer(
  state: CompanyInfoState = initialState,
  action: CompanyInfoActionTypes
): CompanyInfoState {
  switch (action.type) {
    case COMPANY_INFO_UPDATED:
      let newState = {
        ...initialState,
        ...action.payload
      }
      return newState
    case COMPANY_INFO_CLEARED:
      newState = {
        ...initialState
      }
      return newState
    default:
      return state
  }
}
