import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getApplication, getLatestApplication, saveApplication, submitApplication } from '../../services/database'
import { clearAccountInfo, updateAccountInfo } from '../../store/account-info/actions'
import { clearCompanyInfo, updateCompanyInfo } from '../../store/company-info/actions'
import { clearSubmissionFiles, updateSubmissionFiles } from '../../store/submission-files/actions'
import Section from '../templates/section'
import AccountInfoForm from './account-info-form'
import { AccountInfo } from './account-info-types'
import CompanyInfoForm from './company-info-form'
import { CompanyInfo } from './company-info-types'
import { UserInfo } from './sign-in-types'
import SubmissionFilesForm from './submission-files-form'
import { SubmissionFiles } from './submission-files-types'

interface ApplyProps {
  userInfo?: UserInfo
  updateCompanyInfo: (value: CompanyInfo) => void
  updateAccountInfo: (value: AccountInfo) => void
  updateSubmissionFiles: (value: SubmissionFiles) => void
  clearCompanyInfo: () => void
  clearAccountInfo: () => void
  clearSubmissionFiles: () => void
  readOnly?: boolean
}

function Apply(props: ApplyProps) {
  const { /* userInfo, */ companyInfo, accountInfo, submissionInfo } = useSelector((state: any) => {
    const { userInfo, companyInfo, accountInfo, submissionInfo } = state
    return { userInfo, companyInfo, accountInfo, submissionInfo }
  })

  const [step, setStep] = useState(1)

  const [originalId, setOriginalId] = useState<string | undefined>()

  const history = useHistory()
  const autoSaveTimerRef = useRef<NodeJS.Timer | null>(null)
  const applicationRef = useRef({ companyInfo, accountInfo, submissionInfo, originalId })

  const [isValidCompanyInfo, setIsValidCompanyInfo] = useState(true)
  const [isValidAccountInfo, setIsValidAccountInfo] = useState(true)

  const { operation, appId } = useParams() as { operation: string, appId?: string }
  switch (operation) {
    case 'create':
      break
    case 'update':
      break
    case 'copy':
      break
    case 'view':
      break
    default:
      console.assert(false, `Unknown operation ${operation}`)
  }

  function clearTimer() {
    if (autoSaveTimerRef.current !== null) {
      clearInterval(autoSaveTimerRef.current)
      autoSaveTimerRef.current = null
    }
  }

  useEffect(() => {
    applicationRef.current = { companyInfo, accountInfo, submissionInfo, originalId }
  }, [companyInfo, accountInfo, submissionInfo, originalId])

  useEffect(() => {
    return () => {
      // use back or forward button
      if (history.action === 'POP') {
        if (
          history.location.pathname !== '/apply/create' &&
          history.location.pathname !== '/apply/update' &&
          !history.location.pathname.startsWith('/apply/copy') &&
          !history.location.pathname.startsWith('/apply/view')
        ) {
          clearTimer()
        }
      }
    }
  })

  const autoSave = async () => {
    try {
      await saveApplication(applicationRef.current)
      alert('申請書が自動保存されました。')
    } catch (error) {
      alert('申請書の自動保存が完了しませんでした。')
    }
  }

  useEffect(() => {
    // 5 minutes
    // eslint-disable-next-line
    if (operation !== 'view') {
      autoSaveTimerRef.current = setInterval(autoSave, 5 * 60 * 1000)
    }
  }, [operation])

  const uid = props.userInfo?.uid
  const {
    updateCompanyInfo,
    updateAccountInfo,
    updateSubmissionFiles,
    clearCompanyInfo,
    clearAccountInfo,
    clearSubmissionFiles
  } = props
  useEffect(() => {
    // clear data on redux
    clearCompanyInfo()
    clearAccountInfo()
    clearSubmissionFiles()

    if (operation === 'update' && uid) {
      getLatestApplication({
        uid
      })
      .then((data: any) => {
        if (data && data.data) {
          const { companyInfo, accountInfo, submissionInfo, originalId, appId } = data.data
          updateCompanyInfo(companyInfo)
          updateAccountInfo(accountInfo)
          updateSubmissionFiles(submissionInfo)
          setOriginalId(originalId || appId)
        }
      })
      .catch((error) => {
        console.error(error)
        alert('処理が完了できませんでした。')
        history.replace('/')
      })
    } else if ((operation === 'copy' || operation === 'view') && appId) {
      console.log({operation, appId})
      getApplication({
        appId
      })
      .then((data: any) => {
        console.log({data})
        if (data && data.data) {
          const { companyInfo, accountInfo, submissionInfo } = data.data
          updateCompanyInfo(companyInfo)
          updateAccountInfo(accountInfo)
          updateSubmissionFiles(submissionInfo)
          setOriginalId(undefined)
        }
      })
      .catch((error) => {
        console.error(error)
        alert('処理が完了できませんでした。')
        history.replace('/')
      })
    }
  }, [
    operation,
    appId,
    uid,
    updateCompanyInfo,
    updateAccountInfo,
    updateSubmissionFiles,
    clearCompanyInfo,
    clearAccountInfo,
    clearSubmissionFiles,
    history
  ])

  function handleBack() {
    if (step > 1) {
      setStep(step - 1)
    }
  }

  function handleNext() {
    if (step < 3) {
      setStep(step + 1)
    }
  }

  async function handleSubmit() {
    clearTimer()
    try {
      await submitApplication({ companyInfo, accountInfo, submissionInfo, originalId })
      alert('申請書が送信されました。')
    } catch (error) {
      console.log({ error })
      alert('申請書を送信することができませんでした。')
    }
    history.replace('/')
  }

  async function handleSave() {
    clearTimer()
    try {
      await saveApplication({ companyInfo, accountInfo, submissionInfo, originalId })
      alert('申請書が保存されました。')
    } catch (error) {
      console.log({ error })
      alert('申請書の保存が完了しませんでした。')
    }
    history.replace('/')
  }

  function handleCopy() {
    history.push(`/apply/copy/${appId}`)
  }

  function handleCancel() {
    clearTimer()
    history.replace('/')
  }

  return (
    <div className="page-apply">
      <h1 className="page-header">+メッセージ公開申請</h1>
      <div className="container">
        <div className={`row ${operation === 'view' ? '' : 'justify-content-xl-center'}`}>
          <div className="col-xl-6" style={(step === 1 || operation === 'view') ? undefined : {display: 'none'}}>
            <Section title="企業情報" body={<CompanyInfoForm onValidated={setIsValidCompanyInfo} readOnly={operation === 'view'}/>} />
          </div>
          <div className="col-xl-6" style={(step === 2 || operation === 'view') ? undefined : {display: 'none'}}>
            <Section title="公式アカウント情報" body={<AccountInfoForm onValidated={setIsValidAccountInfo} readOnly={operation === 'view'} />} />
          </div>
          <div className="col-xl-6" style={(step === 3 || operation === 'view') ? undefined : {display: 'none'}}>
            <Section title="提出ファイル" body={<SubmissionFilesForm readOnly={operation === 'view'} />} />
          </div>
        </div>
        <div className="row">
          <div className="col buttons-container">
            <button type="button" className="btn btn-sm btn-default m-r-5" onClick={(step === 1) ? handleCancel : handleBack}>
            {(step === 1) ? 'キャンセル' : '戻る'}
            </button>
            {
              (operation === 'view' && appId)
              ?
              <>
               <button type="button" className="btn btn-sm btn-default m-r-5" onClick={handleCopy}>
                  コピー
                </button>
              </>
              :
              <>
                <button type="button" className="btn btn-sm btn-default m-r-5" onClick={handleSave}>
                  保存
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  disabled={
                    (step === 1 && !isValidCompanyInfo)
                    || (step === 2 && !isValidAccountInfo)
                  }
                  onClick={(step === 3) ? handleSubmit : handleNext}>
                  {(step === 3) ? '送信' : '次へ'}
                </button>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state: any) {
  const { userInfo } = state
  return { userInfo }
}

export default connect(mapStateToProps, {
  updateCompanyInfo,
  updateAccountInfo,
  updateSubmissionFiles,
  clearCompanyInfo,
  clearAccountInfo,
  clearSubmissionFiles
})(Apply)
