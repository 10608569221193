import * as React from 'react'
import { StaticMenu } from '../../ui-components/pages/account-info-types'

interface StaticMenuInputProps {
  label: string
  values: StaticMenu[]
  onChange: (value: StaticMenu[]) => void
}

function StaticMenuInput(props: StaticMenuInputProps) {
  const { label, values, onChange } = props

  const setLabel = React.useCallback(
    (index, value) => {
      const newValues = [...values]
      newValues[index] = {
        ...newValues[index],
        label: value
      }
      onChange(newValues)
    },
    [values, onChange]
  )

  const setUrl = React.useCallback(
    (index, value) => {
      const newValues = [...values]
      newValues[index] = {
        ...newValues[index],
        url: value
      }
      onChange(newValues)
    },
    [values, onChange]
  )

  const validateStaticMenu = (index: number) => {
    const newValues = [...values]
    if (newValues[index]) {
      const { label, url } = newValues[index]
      if (!label || !url) {
        if (!label && !url) {
          return true
        } else {
          return false
        }
      }
    }
    return true
  }

  return (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-3">{label}</label>
      <div className="col-md-9">
        <ol>
          {new Array(5).fill(0).map((_, index) => (
            <li key={index}>
              <div className="row">
                <label className="col-md-3 col-form-label">ラベル：</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control m-b-5"
                    placeholder="ラベルを入力してください"
                    data-parsley-trigger="input"
                    data-parsley-validation-threshold="0"
                    data-parsley-error-message="入力が無効です"
                    value={values.length > index ? values[index].label || '' : ''}
                    onChange={(event) => setLabel(index, event.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <label className="col-md-3 col-form-label">URL：</label>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control m-b-5"
                    placeholder="URLを入力してください"
                    data-parsley-type="url"
                    data-parsley-trigger="input"
                    data-parsley-validation-threshold="0"
                    data-parsley-error-message="URLの形式で入力してください"
                    value={values.length > index ? values[index].url || '' : ''}
                    onChange={(event) => setUrl(index, event.target.value)}
                  />
                </div>
              </div>
              {!validateStaticMenu(index) && (
                <ul className="parsley-errors-list filled" aria-hidden="false">
                  <li className="parsley-custom-error-message">
                    ラベルとURLの両方を入力してください
                  </li>
                </ul>
              )}
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}

export default StaticMenuInput
