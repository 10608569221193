import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Section from '../templates/section'
import DemoAccountForm from './demo-account-form'
import { DemoAccount } from './demo-account-types'
import { clearDemoAccount, updateDemoAccount } from '../../store/demo-account/actions'
import { submitDemoAccountApplication } from '../../services/database'
import { UserInfo } from './sign-in-types'

interface Props {
  userInfo?: UserInfo
  demoAccount?: DemoAccount
  updateDemoAccount: (value: DemoAccount) => void
  clearDemoAccount: () => void
}

const DemoAccountDisplay = (props: Props) => {
  const { operation } = useParams() as { operation: string }
  switch (operation) {
    case 'create':
      break
    case 'update':
      break
    default:
      console.assert(false, `Unknown operation ${operation}`)
  }

  //const uid = props.demoAccount?.uid
  const { updateDemoAccount, clearDemoAccount } = props
  useEffect(() => {
    // clear data on redux
    clearDemoAccount()

    /* if (operation === 'update' && uid) {
      getLatestApplication({
        uid
      }).then((data: any) => {
        console.log({ data })
        if (data && data.data) {
          const { companyInfo, accountInfo, submissionInfo } = data.data
          updateCompanyInfo(companyInfo)
          updateAccountInfo(accountInfo)
          updateSubmissionFiles(submissionInfo)
        }
      })
    }*/
  }, [
    operation,
    //uid,
    updateDemoAccount,
    clearDemoAccount
  ])

  const { demoAccount } = useSelector((state: any) => {
    const { demoAccount } = state
    return { demoAccount }
  })

  const [isValidated, setIsValidated] = useState(false)

  const history = useHistory()

  async function handleSubmit() {
    try {
      await submitDemoAccountApplication({ demoAccount })
      alert('申請書が送信されました。')
    } catch (error) {
      alert('申請書を送信することができませんでした。')
    }
    history.replace('/')
  }

  const handleCancel = () => {
    history.replace('/')
  }

  return (
    <div className="page-apply">
      <h1 className="page-header">+メッセージ デモ アカウント申請</h1>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3">
            <Section
              title="デモ アカウント"
              body={<DemoAccountForm updateIsValidated={setIsValidated} />}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 offset-xl-3 buttons-container ">
            <button type="button" className="btn btn-sm btn-default m-r-5" onClick={handleCancel}>
              戻る
            </button>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={handleSubmit}
              disabled={!isValidated}
            >
              送信
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state: any) {
  const { userInfo, demoAccount } = state
  return { userInfo, demoAccount }
}

export default connect(mapStateToProps, { updateDemoAccount, clearDemoAccount })(DemoAccountDisplay)
