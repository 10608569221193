import { UserInfo } from '../../ui-components/pages/sign-in-types'

export const USER_INFO_UPDATED = 'userInfo/Updated'
export const USER_INFO_CLEARED = 'userInfo/Cleared'

export type UserInfoState = UserInfo

type UserInfoUpdatedAction = {
  type: typeof USER_INFO_UPDATED
  payload: UserInfo
}

type UserInfoClearedAction = {
  type: typeof USER_INFO_CLEARED
}

export type UserInfoActionTypes = UserInfoUpdatedAction | UserInfoClearedAction
