import {
  AccountInfoActionTypes,
  AccountInfoState,
  ACCOUNT_INFO_CLEARED,
  ACCOUNT_INFO_UPDATED
} from './types'

const initialState: AccountInfoState = {
  displaySettings: {
    plannedStartDate: '',
    nicknameFirstChoice: '',
    nicknameSecondChoice: '',
    nicknameThirdChoice: '',
    titleEn: '',
    descriptionEn: '',
    shortDescriptionEn: '',
    titleJa: '',
    descriptionJa: '',
    shortDescriptionJa: '',
    telNo: '',
    address: '',
    addressLat: '',
    addressLon: '',
    email: '',
    websiteURL: '',
    welcomeMessage: '',
    licenseURL: '',
    hasIconImage: false,
    hasWelcomeImage: false,
    hasBackgroundImage: false,
    publish: false,
    searchTerms: []
  },
  operationSettings: {
    allowTextReply: false,
    allowFileTransfer: false,
    allowGeolocation: false,
    allowRegistration: false,
    autoReplyErrorMessage: '',
    staticMenu: []
  },
  useCase: {
    name: '',
    usage: '',
    targetUser: '',
    description: '',
    expectedTraffic: '',
    plannedMaxRCSTransmissions: 0,
    categories: ['すべて']
  },
  connection: {
    adminAccessIP: '',
    apiAccessIP: '',
    webhookURL: ''
  }
}

export function accountInfoReducer(
  state: AccountInfoState = initialState,
  action: AccountInfoActionTypes
): AccountInfoState {
  switch (action.type) {
    case ACCOUNT_INFO_UPDATED:
      let newState = {
        ...initialState,
        ...action.payload
      }
      return newState
    case ACCOUNT_INFO_CLEARED:
      newState = {
        ...initialState
      }
      return newState
    default:
      return state
  }
}
