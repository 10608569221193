import * as React from 'react'
import { Validator } from '../../ui-helpers/validators'

interface TextsInputProps {
  label: string
  placeHolder: string
  message: string
  validator?: Validator
  values: string[]
  onChange: (value: string[]) => void
  readOnly?: boolean
}

function TextsInput(props: TextsInputProps) {
  const { label, placeHolder, validator, message, values, onChange, readOnly } = props

  const setText = React.useCallback((index, value) => {
    const newValues = [...values]
    newValues[index] = value
    onChange(newValues)
  }, [values, onChange])

  return (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-3">{label}</label>
      <div className="col-md-9">
        <ol>
          {
            new Array(5).fill(0).map((_, index) => (
              <li key={index}>
                <input
                  type="text"
                  className="form-control m-b-5"
                  placeholder={(readOnly) ? '' : placeHolder}
                  data-parsley-type={validator}
                  data-parsley-trigger="input"
                  data-parsley-validation-threshold="0"
                  data-parsley-error-message={message}
                  value={(values.length > index) ? values[index] || '' : ''}
                  onChange={(event) => setText(index, event.target.value)}
                  readOnly={readOnly}
                />
              </li>
            ))
          }
        </ol>
      </div>
    </div>
  )
}

export default TextsInput
