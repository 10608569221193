import { AccountInfo } from '../../ui-components/pages/account-info-types'

export const ACCOUNT_INFO_UPDATED = 'accountInfo/Updated'
export const ACCOUNT_INFO_CLEARED = 'accountInfo/Cleared'

export type AccountInfoState = AccountInfo

type AccountInfoUpdatedAction = {
  type: typeof ACCOUNT_INFO_UPDATED
  payload: AccountInfo
}

type AccountInfoClearedAction = {
  type: typeof ACCOUNT_INFO_CLEARED
}

export type AccountInfoActionTypes = AccountInfoUpdatedAction | AccountInfoClearedAction
