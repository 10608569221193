import * as React from 'react'
import { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { clearDemoAccount, updateDemoAccount } from '../../store/demo-account/actions'
import EmailInput from '../molecules/email-input'
import TelNoInput from '../molecules/tel-no-input'
import TextInput from '../molecules/text-input'
import { DemoAccount } from './demo-account-types'

const $ = require('jquery')

interface DemoAccountFormProps {
  demoAccount: DemoAccount
  updateIsValidated?: (value: boolean) => void
  updateDemoAccount: (value: DemoAccount) => void
  clearDemoAccount: () => void
}

const DemoAccountForm = (props: DemoAccountFormProps) => {
  const { clearDemoAccount } = props

  let parsleyFormRef = React.useRef<any>()

  // clear account info on component-will-unmount
  useEffect(() => {
    parsleyFormRef.current = $('#demo-account-form').parsley()
    return () => {
      clearDemoAccount()
    }
  }, [clearDemoAccount])

  const setDemoAccount = useCallback(
    <K extends keyof DemoAccount>(key: K, value: DemoAccount[K]) => {
      const newDemoAccount = {
        ...props.demoAccount,
        [key]: value
      }
      props.updateDemoAccount(newDemoAccount)
      if (props.updateIsValidated) {
        const isValidated =
          !!newDemoAccount.uid &&
          !!newDemoAccount.companyName &&
          !!newDemoAccount.name &&
          !!newDemoAccount.email &&
          !!newDemoAccount.telNo &&
          !!newDemoAccount.telNo1 &&
          parsleyFormRef.current?.isValid()
        props.updateIsValidated(isValidated)
      }
    },
    [props]
  )

  return (
    <form id="demo-account-form">
      <fieldset>
        <TextInput
          label="ID*"
          placeHolder="IDを入力してください"
          message="入力が無効です"
          value={props.demoAccount ? props.demoAccount.uid : ''}
          validator="hankaku_english"
          onChange={(value) => setDemoAccount('uid', value)}
        />
        <TextInput
          label="氏名*"
          placeHolder="氏名を入力してください"
          message="入力が無効です"
          value={props.demoAccount ? props.demoAccount.name : ''}
          onChange={(value) => setDemoAccount('name', value)}
        />
        <TextInput
          label="会社名*"
          placeHolder="会社名を入力してください"
          message="入力が無効です"
          value={props.demoAccount ? props.demoAccount.companyName : ''}
          onChange={(value) => setDemoAccount('companyName', value)}
        />
        <TelNoInput
          label="連絡先電話番号*"
          placeHolder="電話番号を入力してください。例：123-4567-8901"
          message="入力が無効です"
          value={props.demoAccount ? props.demoAccount.telNo : ''}
          onChange={(value) => setDemoAccount('telNo', value)}
        />
        <EmailInput
          label="メールアドレス*"
          placeHolder="メールアドレスを入力してください"
          message="入力が無効です"
          value={props.demoAccount ? props.demoAccount.email : ''}
          onChange={(value) => setDemoAccount('email', value)}
        />
        <p>下記にデモ利用時に送信する携帯電話番号を5つまでご指定ください。</p>
        <TelNoInput
          label="送信先携帯電話番号1*"
          placeHolder="電話番号を入力してください。例：123-4567-8901"
          message="入力が無効です"
          value={props.demoAccount ? props.demoAccount.telNo1 : ''}
          onChange={(value) => setDemoAccount('telNo1', value)}
        />
        <TelNoInput
          label="送信先携帯電話番号2"
          placeHolder="電話番号を入力してください。例：123-4567-8901"
          message="入力が無効です"
          value={props.demoAccount ? props.demoAccount.telNo2 : ''}
          onChange={(value) => setDemoAccount('telNo2', value)}
        />
        <TelNoInput
          label="送信先携帯電話番号3"
          placeHolder="電話番号を入力してください。例：123-4567-8901"
          message="入力が無効です"
          value={props.demoAccount ? props.demoAccount.telNo3 : ''}
          onChange={(value) => setDemoAccount('telNo3', value)}
        />
        <TelNoInput
          label="送信先携帯電話番号4"
          placeHolder="電話番号を入力してください。例：123-4567-8901"
          message="入力が無効です"
          value={props.demoAccount ? props.demoAccount.telNo4 : ''}
          onChange={(value) => setDemoAccount('telNo4', value)}
        />
        <TelNoInput
          label="送信先携帯電話番号5"
          placeHolder="電話番号を入力してください。例：123-4567-8901"
          message="入力が無効です"
          value={props.demoAccount ? props.demoAccount.telNo5 : ''}
          onChange={(value) => setDemoAccount('telNo5', value)}
        />
      </fieldset>
      <section className="d-flex">
        <span className="mx-auto">3営業日以内にご返答いたします。</span>
      </section>
    </form>
  )
}

const mapStateToProps = (state: any) => {
  const { demoAccount } = state
  return { demoAccount }
}

export default connect(mapStateToProps, { updateDemoAccount, clearDemoAccount })(DemoAccountForm)
