import * as React from 'react'

interface Props {
  label: string
  placeHolder: string
  message: string
  value: string
  onChange: (value: string) => void
}

class EmailInput extends React.Component<Props> {
  render() {
    const { label, placeHolder, message, value, onChange } = this.props
    return (
      <div className="form-group row m-b-15">
        <label className="col-form-label col-md-3">{label}</label>
        <div className="col-md-9">
          <input
            type="email"
            className="form-control m-b-5"
            placeholder={placeHolder}
            data-parsley-type="email"
            data-parsley-trigger="input"
            data-parsley-validation-threshold="0"
            data-parsley-error-message={message}
            value={value || ''}
            onChange={(event) => onChange(event.target.value)}
          />
          {/* <small className="f-s-12 text-grey-darker">{message}</small> */}
        </div>
      </div>
    )
  }
}

export default EmailInput
