import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'
import { rootReducer } from './store'

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore(preloadedState: any) {
  const store: any = createStore(persistedReducer, preloadedState, composeWithDevTools())
  const persistor = persistStore(store)
  return { store, persistor }
}
