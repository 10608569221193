import { DemoAccount } from '../../ui-components/pages/demo-account-types'

export const DEMO_ACCOUNT_UPDATED = 'demoAccount/Updated'
export const DEMO_ACCOUNT_CLEARED = 'demoAccount/Cleared'

export type DemoAccountState = DemoAccount

type DemoAccountUpdatedAction = {
  type: typeof DEMO_ACCOUNT_UPDATED
  payload: DemoAccount
}

type DemoAccountClearedAction = {
  type: typeof DEMO_ACCOUNT_CLEARED
}

export type DemoAccountActionTypes = DemoAccountUpdatedAction | DemoAccountClearedAction
