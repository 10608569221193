import * as React from 'react'
import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx'

interface SectionProps {
  title: string
  body: JSX.Element
}

function Section(props: SectionProps) {
  const { title, body } = props
  return (
    <Panel className="with-shadow">
      <PanelHeader noButton={true}>{title}</PanelHeader>
      <PanelBody>{body}</PanelBody>
    </Panel>
  )
}

export default Section
