import * as React from 'react'
import { connect } from 'react-redux'
import { clearSubmissionFiles, updateSubmissionFiles } from '../../store/submission-files/actions'
import FileUpload from '../molecules/file-upload'
import { SubmissionFiles } from './submission-files-types'

interface SubmissionFilesFormProps {
  submissionInfo: SubmissionFiles
  updateSubmissionFiles: (value: SubmissionFiles) => void
  clearSubmissionFiles: () => void
  readOnly?: boolean
}

function SubmissionFilesForm(props: SubmissionFilesFormProps) {
  const { submissionInfo, clearSubmissionFiles } = props
  // clear submission files on component-will-unmount
  React.useEffect(() => {
    return () => {
      clearSubmissionFiles()
    }
  }, [clearSubmissionFiles])

  const setSubmissionFiles = React.useCallback(<K extends keyof SubmissionFiles>(key: K, value: SubmissionFiles[K]) => {
    props.updateSubmissionFiles({
      ...props.submissionInfo,
      [key]: value,
    })
  }, [props])

  return (
    <form id="submission-files-form">
      <fieldset>
        <legend>提出ファイル</legend>
        <FileUpload
          label="登記簿謄本"
          placeHolder="登記簿謄本ファイル名を入力してください"
          message="入力が無効です"
          accept=".pdf,image/*"
          value={submissionInfo.registrationCopyFileName}
          onChange={(value) => setSubmissionFiles('registrationCopyFileName', value)}
        />
        <FileUpload
          label="会社概要"
          placeHolder="会社概要ファイル名を入力してください"
          message="入力が無効です"
          accept=".pdf,image/*"
          value={submissionInfo.companyOverviewFileName}
          onChange={(value) => setSubmissionFiles('companyOverviewFileName', value)}
        />
        <FileUpload
          label="アイコン画像"
          placeHolder="アイコン画像ファイル名を入力してください"
          message="入力が無効です"
          accept="image/*"
          value={submissionInfo.iconImageFileName}
          onChange={(value) => setSubmissionFiles('iconImageFileName', value)}
        />
        <FileUpload
          label="ウェルカム画像"
          placeHolder="ウェルカム画像ファイル名を入力してください"
          message="入力が無効です"
          accept="image/*"
          value={submissionInfo.welcomeImageFileName}
          onChange={(value) => setSubmissionFiles('welcomeImageFileName', value)}
        />
        <FileUpload
          label="背景画像"
          placeHolder="背景画像ファイル名を入力してください"
          message="入力が無効です"
          accept="image/*"
          value={submissionInfo.backgroundImageFileName}
          onChange={(value) => setSubmissionFiles('backgroundImageFileName', value)}
        />
      </fieldset>
    </form>
  )
}

function mapStateToProps(state: any) {
  const { submissionInfo } = state
  return { submissionInfo }
}

export default connect(mapStateToProps, { updateSubmissionFiles, clearSubmissionFiles })(SubmissionFilesForm)
