import React from 'react'
import { Link } from 'react-router-dom'

function SelectOperation() {
  return (
    <div className="page-select-operation">
      <h1 className="page-header">アカウントの処理</h1>
      <div className="row m-b-10">
        <div className="col btn-container">
          <Link className="btn btn-primary" to="/demo-account/create">
            デモアカウントの作成
          </Link>
        </div>
      </div>
      <div className="row m-b-10">
        <div className="col btn-container">
          <Link className="btn btn-primary" to="/apply/create">
            商用アカウント（申請書）の作成
          </Link>
        </div>
      </div>
      <div className="row m-b-10">
        <div className="col btn-container">
          <Link className="btn btn-default" to="/apply/update">
            商用アカウント（保存済み申請書）の編集
          </Link>
        </div>
      </div>
      <div className="row m-b-10">
        <div className="col btn-container">
          <Link className="btn btn-default" to="/applications">
            商用アカウント（申請書）の一覧
          </Link>
        </div>
      </div>
      <div className="row m-b-10">
        <div className="col text-center">
          <p></p>
          <p>
            ※アクリートのサービスをご利用中のお客様は上記から申込をお願いします。
            <br />
            初めてアクリートのサービスをご利用されるお客様は下記ページから問い合わせをお願いします。
            <br />
            <a
              href="https://www.accrete-inc.com/contact/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.accrete-inc.com/contact/
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SelectOperation
