import {
  SubmissionFilesActionTypes,
  SubmissionFilesState,
  SUBMISSION_FILES_CLEARED,
  SUBMISSION_FILES_UPDATED
} from './types'

const initialState: SubmissionFilesState = {
  registrationCopyFileName: undefined,
  companyOverviewFileName: undefined,
  iconImageFileName: undefined,
  welcomeImageFileName: undefined,
  backgroundImageFileName: undefined
}

export function submissionFilesReducer(
  state: SubmissionFilesState = initialState,
  action: SubmissionFilesActionTypes
): SubmissionFilesState {
  switch (action.type) {
    case SUBMISSION_FILES_UPDATED:
      let newState = {
        ...initialState,
        ...action.payload
      }
      return newState
    case SUBMISSION_FILES_CLEARED:
      newState = {
        ...initialState
      }
      return newState
    default:
      return state
  }
}
