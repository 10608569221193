import * as React from 'react'
import { FileModel } from '../pages/submission-files-types'
import { firebase } from '../../services/firebase'
import moment from 'moment'
import { randomString, uuidv4 } from '../../ui-helpers/utils'

interface FileUploadProps {
  label: string
  placeHolder: string
  message: string
  accept?: string
  value?: FileModel
  onChange: (value?: FileModel) => void
}

function FileUpload(props: FileUploadProps) {
  const { label, placeHolder, accept, value, onChange } = props
  const [id] = React.useState(uuidv4())

  const [uploadingFile, setUploadingFile] = React.useState<File>()

  const uploadFile = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      const file = files[0]
      const now = moment()
      const filePath = `UPLOADED/${moment().format('YYYYMM')}/${now.format('DD')}_${randomString(10)}_${file.name}`
      const task = firebase.storage().ref().child(filePath).put(file, {
        contentType: file.type,
      })
      setUploadingFile(file)
      task.then((snapshot) => snapshot.ref.getDownloadURL())
      .then((downloadUrl) => {
        onChange({
          filename: file.name,
          downloadUrl,
        })
      })
      .finally(() => setUploadingFile(undefined))
    }
  }, [onChange])

  const deleteFile = React.useCallback(() => {
    if (window.confirm('このファイルを削除してもよろしいですか？')) {
      onChange(undefined)
    }
  }, [onChange])

  return (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-3">{label}</label>
      {
        (value)
        ?
        <>
          <div className="col-md-9">
            <div className="input-group m-b-5">
              {/* <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className={`fa fa-fw fa-${getFontawesomeIconFromFilename(value.filename)}`}></i>
                </div>
              </div> */}
              <div className="form-control text-nowrap text-truncate">
                <i className={`fa fa-fw fa-${getFontawesomeIconFromFilename(value.filename)} m-r-4`}></i>
                {value.filename || ''}
              </div>
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn input-group-text"
                  onClick={deleteFile}
                  style={{width: 80, justifyContent: 'center'}}
                >
                  <i className="fa fa-fw fa-trash"></i>
                  {/* <span>削除</span> */}
                </button>
              </div>
            </div>
            <PreviewFile file={value} />
          </div>
        </>
        :
        <>
          <div className="col-md-9">
            <label className="input-group m-b-5" htmlFor={id}>
              {/* <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className={`fa fa-fw fa-${getFontawesomeIconFromFilename(value.filename)}`}></i>
                </div>
              </div> */}
              <div className="form-control text-nowrap text-truncate" style={(uploadingFile) ? undefined : {color:'#6A7279'}}>
                {
                  (uploadingFile)
                  ?
                  <i className={`fa fa-fw fa-${getFontawesomeIconFromFilename(uploadingFile.name)} m-r-4`}></i>
                  :
                  null
                }
                {uploadingFile?.name || placeHolder}
              </div>
              <div className="input-group-append">
                <div className="btn input-group-text" style={{width: 80, justifyContent: 'center'}}>
                  {
                    (uploadingFile)
                      ?
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      :
                      <span>選択する</span>
                  }
                </div>
              </div>
            </label>
            <input
              id={id}
              type="file"
              className="custom-file-input"
              accept={accept}
              onChange={uploadFile}
              hidden={true}
              disabled={!!uploadingFile}
            />
          </div>
          {/* <div className="col-md-1">
            <button type="button" className="btn btn-primary start m-r-3">
              <i className="fa fa-fw fa-upload"></i>
              <span>アップロード</span>
            </button>
          </div>
          <div className="col-md-1">
            <button type="button" className="btn btn-default delete m-r-3">
              <i className="fa fa-fw fa-trash"></i>
              <span>削除</span>
            </button>
          </div> */}
        </>
      }
    </div>
  )
}

const PreviewFile = (props: {file: FileModel}) => {
  const {file} = props

  const isImageFile = (filename: string) => {
    filename = (filename || '').toLocaleLowerCase()
    return filename && (
      filename.endsWith('.png')
      || filename.endsWith('.jpg')
      || filename.endsWith('.jpeg')
      || filename.endsWith('.gif')
    )
  }

  return (
    <div className="col-md-6 p-l-0 p-r-0">
      {
        isImageFile(file.filename)
        ?
        <div
          style={{
            width: '100%',
            height: 120,
            border: '1px solid #efefef',
            borderRadius: 4,
            // backgroundColor: '#efefef',
            backgroundImage: `url("${file.downloadUrl}")`,
            backgroundSize: 'contain',
            backgroundPositionX: 'center',
            backgroundPositionY: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        :
        null
      }
    </div>
  )
}

const getFontawesomeIconFromFilename = (filename?: string) => {
  const ext = filename?.toLocaleLowerCase().split('.').pop()

  switch(ext) {
    case 'txt':
      return 'file-alt'
    
    case 'xls':
    case 'xlsx':
      return 'file-excel'
    
    case 'doc':
    case 'docx':
      return 'file-word'

    case 'ppt':
    case 'pptx':
      return 'file-powerpoint'
    
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
      return 'file-image'

    case 'pdf':
      return 'file-pdf'

    default:
      return 'file'
  }
}

export default FileUpload
