import { SubmissionFiles } from '../../ui-components/pages/submission-files-types'

export const SUBMISSION_FILES_UPDATED = 'submissionFiles/Updated'
export const SUBMISSION_FILES_CLEARED = 'submissionFiles/Cleared'

export type SubmissionFilesState = SubmissionFiles

type SubmissionFilesUpdatedAction = {
  type: typeof SUBMISSION_FILES_UPDATED
  payload: SubmissionFiles
}

type SubmissionFilesClearedAction = {
  type: typeof SUBMISSION_FILES_CLEARED
}

export type SubmissionFilesActionTypes = SubmissionFilesUpdatedAction | SubmissionFilesClearedAction
