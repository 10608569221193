import React, { useCallback, useRef, useState } from 'react'
import { firebase } from './firebase'
import { UserInfo } from '../ui-components/pages/sign-in-types'
import FirebaseUIAuth from 'react-firebaseui-localized'
import '../scss/accrete/sign-in.scss'

// react-firebaseui-localized use firebase on window
;(window as any).firebase = firebase

interface SignInUIProps {
  signInSuccessUrl: string
  updateUserInfo: (value: UserInfo) => void
}

function SignInUI(props: SignInUIProps) {
  const [shouldShowExtraInfo, setShouldShowExtraInfo] = useState(true)

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      var defaultEmail = (user.providerData && user.providerData.length > 0) ? user.providerData[0]!.email : null
      props.updateUserInfo({
        displayName: user.displayName || '',
        email: defaultEmail || user.email || '',
        photoURL: user.photoURL || '',
        uid: user.uid || ''
      })
    }
  })

  const browserLocale = navigator.language.split('-')[0]
  const language = browserLocale === 'ja' ? 'ja' : 'en'

  const handleUiShown = useCallback(() => {
    const elems = document.getElementsByClassName('firebaseui-callback-indicator-container')
    setShouldShowExtraInfo(elems.length === 0)
  }, [])

  const uiConfig = useRef({
    signInFlow: 'redirect',
    signInSuccessUrl: props.signInSuccessUrl,
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      uiShown: handleUiShown,
    },
  
  })

  return (
    <div>
      <FirebaseUIAuth lang={language} version="4.8.0" config={uiConfig.current} auth={firebase.auth()} />
      {
        (shouldShowExtraInfo)
        ?
        <div className="info-wrapper">
          株式会社アクリートの「
          <a
            href="https://www.accrete-inc.com/company/privacy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            個人情報保護方針および個人情報の取扱い
          </a>
          」「
          <a
            href="https://www.accrete-inc.com/company/security.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            情報セキュリティ基本方針
          </a>
          」ならびに「
          <a
            href="https://www.accrete-inc.com/rcs_docs/NDA.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            秘密情報の取り扱い
          </a>
          」に同意し、アクリートポータルを利用します。
        </div>
        :
        null
      }
    </div>
  )
}

export default SignInUI
