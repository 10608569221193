import React from 'react'
import { Link } from 'react-router-dom'
import { PageSettings } from './../../config/page-settings.js'
import { connect } from 'react-redux'
import { clearUserInfo } from '../../store/user-info/actions'
import { firebase } from '../../services/firebase'
import { withRouter } from 'react-router-dom'

class SidebarProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profileActive: 1,
      showDemoAndApply: true,
      showDlGuide: true
    }
    this.handleProfileExpand = this.handleProfileExpand.bind(this)
    this.signOut = this.signOut.bind(this)
  }

  componentDidMount() {
    if (
      this.props.history.location.pathname === '/apply/create' ||
      this.props.history.location.pathname === '/demo-account/create' ||
      this.props.history.location.pathname === '/apply/update'
    ) {
      this.setState((state) => ({
        showDemoAndApply: false
      }))
    }

    if (this.props.history.location.pathname !== '/select-operation') {
      this.setState((state) => ({
        showDlGuide: false
      }))
    }
  }

  componentDidUpdate(previousProps, previousState) {
    if (
      this.props.history.location.pathname === '/apply/create' ||
      this.props.history.location.pathname === '/demo-account/create' ||
      this.props.history.location.pathname === '/apply/update'
    ) {
      if (previousState.showDemoAndApply) {
        this.setState((state) => ({
          showDemoAndApply: false
        }))
      }
    } else {
      if (!previousState.showDemoAndApply) {
        this.setState((state) => ({
          showDemoAndApply: true
        }))
      }
    }

    if (this.props.history.location.pathname !== '/select-operation') {
      if (previousState.showDlGuide) {
        this.setState((state) => ({
          showDlGuide: false
        }))
      }
    } else {
      if (!previousState.showDlGuide) {
        this.setState((state) => ({
          showDlGuide: true
        }))
      }
    }
  }

  handleProfileExpand(e) {
    e.preventDefault()
    this.setState((state) => ({
      profileActive: !this.state.profileActive
    }))
  }

  async signOut() {
    if (window.confirm('ログアウトしてもよろしいですか？')) {
      await firebase.auth().signOut()
      this.props.clearUserInfo()
      window.location.href = '/sign-in'
    }
  }

  handleDownloadGuide(e) {
    e.preventDefault()
    window.open('https://www.accrete-inc.com/rcs_docs/Developer_guide.pdf', '_blank')
  }

  handleDownloadStarted(e) {
    e.preventDefault()
    window.open('https://www.accrete-inc.com/rcs_docs/GettingStarted.pdf', '_blank')
  }

  handleDownloadSample(e) {
    e.preventDefault()
    window.open('https://www.accrete-inc.com/rcs_docs/SampleCode.pdf', '_blank')
  }

  handleDownloadAPIGuide(e) {
    e.preventDefault()
    window.open(
      'https://www.accrete-inc.com/rcs_docs/Accrete_D10RCS_API%E4%BB%95%E6%A7%98%E6%9B%B8_G3_Ver0.1.0_Draft_TA2003005.pdf',
      '_blank'
    )
  }

  render() {
    const { userInfo } = this.props

    return userInfo.uid ? (
      <PageSettings.Consumer>
        {({ pageSidebarMinify }) => (
          <ul className="nav">
            <li className={'nav-profile ' + (this.state.profileActive ? 'expand ' : '')}>
              <Link to="/" onClick={this.handleProfileExpand}>
                <div className="cover with-shadow"></div>
                <div className="image image-icon bg-black text-grey-darker">
                  {userInfo.photoURL ? (
                    <img src={userInfo.photoURL} alt={userInfo.displayName || userInfo.email} />
                  ) : (
                    <i className="fa fa-user"></i>
                  )}
                </div>
                <div className="info">
                  <b className="caret pull-right"></b>
                  {userInfo.displayName}
                  <small>{userInfo.email}</small>
                </div>
              </Link>
            </li>
            <li>
              <ul
                className={
                  'nav nav-profile ' +
                  (this.state.profileActive && !pageSidebarMinify ? 'd-block ' : '')
                }
              >
                {this.state.showDemoAndApply && (
                  <>
                    <li>
                      <Link to="/demo-account/create">
                        <i className="far fa-file-alt"></i> デモアカウントの作成
                      </Link>
                    </li>
                    <li>
                      <Link to="/apply/create">
                        <i className="far fa-file-alt"></i> 商用アカウント（申請書）の作成
                      </Link>
                    </li>
                    <hr className="sidebar-divider" />
                  </>
                )}
                {this.state.showDlGuide && (
                  <>
                    <li>
                      <Link to="/" onClick={this.handleDownloadGuide}>
                        <i className="fa fa-file-download"></i> 導入ガイドのダウンロード
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={this.handleDownloadStarted}>
                        <i className="fa fa-file-download"></i> Getting Startedのダウンロード
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={this.handleDownloadSample}>
                        <i className="fa fa-file-download"></i> サンプル コードのダウンロード
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={this.handleDownloadAPIGuide}>
                        <i className="fa fa-file-download"></i> アクリート RCS API
                        仕様書のダウンロード
                      </Link>
                    </li>
                    <hr className="sidebar-divider" />
                  </>
                )}
                <li>
                  <Link to="/" onClick={this.signOut}>
                    <i className="fa fa-sign-out-alt"></i> サインアウト
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        )}
      </PageSettings.Consumer>
    ) : null
  }
}

function mapStateToProps(state) {
  const { userInfo } = state
  return { userInfo }
}

export default withRouter(connect(mapStateToProps, { clearUserInfo })(SidebarProfile))
