import { UserInfo } from '../../ui-components/pages/sign-in-types'
import { USER_INFO_UPDATED, UserInfoActionTypes, USER_INFO_CLEARED } from './types'

export function updateUserInfo(value: UserInfo): UserInfoActionTypes {
  return {
    type: USER_INFO_UPDATED,
    payload: { ...value }
  }
}

export function clearUserInfo(): UserInfoActionTypes {
  return {
    type: USER_INFO_CLEARED,
  }
}
