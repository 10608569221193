import * as React from 'react'

interface ButtonProps {
  label: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

class Button extends React.Component<ButtonProps> {
  render() {
    const { label, onClick } = this.props
    return (
      <div className="form-group row m-b-15 m-l-3">
          <button onClick={onClick} type="button" className="btn btn-default start m-r-3">
            {label}
          </button>
      </div>
    )
  }
}

export default Button
