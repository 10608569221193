import React, { FC, useEffect } from 'react'
import { firebase } from '../services/firebase'

const Home: FC = () => {
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        window.location.href = '/select-operation'
      } else {
        window.location.href = '/sign-in'
      }
    })
  }, [])

  return (
    <div />
  )
}

export default Home
