import * as React from 'react'

interface PostalCodeInputProps {
  label: string
  placeHolder: string
  message: string
  codeUpper: string
  codeLower: string
  onChange: (value: { codeUpper: string; codeLower: string }) => void
}

function PostalCodeInput(props: PostalCodeInputProps) {
  const { label, placeHolder, message, codeUpper, codeLower, onChange } = props

  const [value, setValue] = React.useState(`${codeUpper}${codeLower ? `-${codeLower}` : ''}`)
  const valueRef = React.useRef(value)
  valueRef.current = value

  React.useEffect(() => {
    const newValue = `${codeUpper}${codeLower ? `-${codeLower}` : ''}`
    if (valueRef.current !== newValue) {
      setTimeout(() => setValue(newValue))
    }
  }, [codeUpper, codeLower, onChange])

  const handleOnChange = React.useCallback(
    (value: string) => {
      const regex = /^[0-9-]*$/
      if (value && value.endsWith('-')) {
        setValue(value)
      } else if (regex.test(value)) {
        const temp = value.split('-')
        onChange({
          codeUpper: temp[0],
          codeLower: temp.length >= 2 ? temp[1] : ''
        })
      }
    },
    [onChange]
  )

  return (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-3">{label}</label>
      <div className="col-md-9">
        <input
          // mask="999-9999"
          type="text"
          className="form-control m-b-5"
          placeholder={placeHolder}
          // maxLength={maxLength}
          data-parsley-type="postal_code"
          data-parsley-trigger="input"
          data-parsley-validation-threshold="0"
          data-parsley-error-message={message}
          value={value}
          onChange={(event) => handleOnChange(event.target.value)}
        />
        {/* <small className="f-s-12 text-grey-darker">{message}</small> */}
      </div>
    </div>
  )
}

export default PostalCodeInput
