import {
  DemoAccountActionTypes,
  DemoAccountState,
  DEMO_ACCOUNT_CLEARED,
  DEMO_ACCOUNT_UPDATED
} from './types'

const initialState: DemoAccountState = {
  uid: '',
  name: '',
  companyName: '',
  email: '',
  telNo: '',
  telNo1: '',
  telNo2: '',
  telNo3: '',
  telNo4: '',
  telNo5: ''
}

export function demoAccountReducer(
  state: DemoAccountState = initialState,
  action: DemoAccountActionTypes
): DemoAccountState {
  switch (action.type) {
    case DEMO_ACCOUNT_UPDATED:
      let newState = {
        ...initialState,
        ...action.payload
      }
      return newState
    case DEMO_ACCOUNT_CLEARED:
      newState = {
        ...initialState
      }
      return newState
    default:
      return state
  }
}
