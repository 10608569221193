import format from 'date-fns/format'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getApplications } from '../../services/database'
import { UserInfo } from './sign-in-types'

const APPLICATION_STATUS_LABEL_MAP: {[key: string]: string} = {
  new: '新規受付',
  testApplied: '試験用申請中',
  testRejected: '試験用差し戻し',
  testEditWaiting: '試験用修正待ち',
  testApproved: '試験用承認済',
  testNotified: '試験用通知済み',
  testDone: '試験済み',
  CommercialApplied: '商用申請中',
  CommercialRejected: '商用差し戻し',
  CommercialEditWaiting: '商用修正待ち',
  CommercialApproved: '商用承認済み',
  CommercialNotified: '商用通知済み',
  Rejected: '取り消し',
}

const APPLICATION_STATUS_COLOR_MAP: {[key: string]: string} = {
  new: 'black',
  testApplied: 'blue',
  testRejected: 'red',
  testEditWaiting: 'chocolate',
  testApproved: 'violet',
  testNotified: 'orange',
  testDone: 'green',
  CommercialApplied: 'blue',
  CommercialRejected: 'red',
  CommercialEditWaiting: 'chocolate',
  CommercialApproved: 'violet',
  CommercialNotified: 'orange',
  Rejected: 'red',
}

interface Props {
  userInfo?: UserInfo
}

function Applications(props: Props) {
  const [applications, setApplications] = useState<any[]>([])

  useEffect(() => {
    async function getApps() {
      try {
        const { data: apps } = await getApplications({ uid: props.userInfo?.uid })
        setApplications(apps)
      } catch (err) {
        alert('申請書リストの取得が完了しませんでした。')
      }
    }

    getApps()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userInfo?.uid])

  const history = useHistory()

  function handleView(appId: string) {
    history.push(`/apply/view/${appId}`)
  }

  function handleCopy(appId: string) {
    history.push(`/apply/copy/${appId}`)
  }

  return (
    <>
      <h1 className="page-header text-center">商用アカウント一覧</h1>
      <div className="container">
        {
          <table id="applicationTable" className="table table-striped table-bordered table-td-valign-middle">
            <thead>
              <tr>
                <th>作成日時</th>
                <th>ID</th>
                <th>State</th>
                <th>ステータス</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {applications
                .map((application) => {
                  const { appId, createdAt, state, status } = application
                  return (
                    <tr key={appId}>
                      <td>{format(new Date(createdAt), 'yyyy/MM/dd HH:mm:ss')}</td>
                      <td>{appId}</td>
                      <td>{state}</td>
                      <td>
                        <select
                          className="form-select" 
                          value={status || ''} 
                          disabled={true}
                          style={{color: APPLICATION_STATUS_COLOR_MAP[status]}}>
                          {Object.keys(APPLICATION_STATUS_LABEL_MAP).map((k) => (
                            <option key={k} value={k} style={{color: APPLICATION_STATUS_COLOR_MAP[k]}}>
                              {APPLICATION_STATUS_LABEL_MAP[k]}
                              {/* {k} */}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="with-btn">
                        <button type="button" className="btn btn-sm btn-primary mr-2" onClick={() => handleView(appId)}>
                          詳細
                        </button>
                        <button type="button" className="btn btn-sm btn-primary" onClick={() => handleCopy(appId)}>
                          コピー
                        </button>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        }
      </div>
    </>
  )
}

function mapStateToProps(state: any) {
  const { userInfo } = state
  return { userInfo }
}

export default connect(
  mapStateToProps,
  {}
)(Applications)
