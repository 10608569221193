import { UserInfoState, UserInfoActionTypes, USER_INFO_UPDATED, USER_INFO_CLEARED } from './types'

const initialState: UserInfoState = {
  displayName: '',
  email: '',
  photoURL: '',
  uid: ''
}

export function userInfoReducer(
  state: UserInfoState = initialState,
  action: UserInfoActionTypes
): UserInfoState {
  switch (action.type) {
    case USER_INFO_UPDATED:
      let newState = {
        ...initialState,
        ...action.payload
      }
      return newState
    case USER_INFO_CLEARED:
      newState = {
        ...initialState
      }
      return newState
    default:
      return state
  }
}
