import * as React from 'react'
import { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { clearAccountInfo, updateAccountInfo } from '../../store/account-info/actions'
import EmailInput from '../molecules/email-input'
import NumberInput from '../molecules/number-input'
import RadioInput from '../molecules/radio-input'
import TelNoInput from '../molecules/tel-no-input'
import TextInput from '../molecules/text-input'
import TextsInput from '../molecules/texts-input'
import StaticMenuInput from '../molecules/staticMenu-input'
import {
  AccountInfo,
  ConnectionInfo,
  DisplaySettings,
  OperationSettings,
  UseCase,
  StaticMenu
} from './account-info-types'
import DateInput from '../molecules/date-input'
// import MultiSelectInput from '../molecules/multi-select-input'

const $ = require('jquery')

interface AccountInfoFormProps {
  accountInfo: AccountInfo
  updateAccountInfo: (value: AccountInfo) => void
  clearAccountInfo: () => void
  onValidated?: (success: boolean) => void
  readOnly?: boolean
}

function AccountInfoForm(props: AccountInfoFormProps) {
  const { clearAccountInfo, onValidated } = props
  const { displaySettings, operationSettings, useCase, connection } = props.accountInfo

  // clear account info on component-will-unmount
  useEffect(() => {
    var form = $('#account-info-form').parsley()
    if (onValidated) {
      form.on('field:validated', () => {
        onValidated(form.isValid())
      })
    }
    return () => {
      clearAccountInfo()
    }
  }, [clearAccountInfo, onValidated])

  const setAccountInfo = useCallback(
    <K extends keyof AccountInfo>(key: K, value: Partial<AccountInfo[K]>) => {
      props.updateAccountInfo({
        ...props.accountInfo,
        [key]: {
          ...props.accountInfo[key],
          ...value
        }
      })
    },
    [props]
  )

  const setDisplaySettings = useCallback(
    <K extends keyof DisplaySettings>(key: K, value: DisplaySettings[K]) => {
      setAccountInfo('displaySettings', {
        [key]: value
      })
    },
    [setAccountInfo]
  )

  const setOperationSettings = useCallback(
    <K extends keyof OperationSettings>(key: K, value: OperationSettings[K]) => {
      setAccountInfo('operationSettings', {
        [key]: value
      })
    },
    [setAccountInfo]
  )

  const setUseCase = useCallback(
    <K extends keyof UseCase>(key: K, value: UseCase[K]) => {
      setAccountInfo('useCase', {
        [key]: value
      })
    },
    [setAccountInfo]
  )

  const setConnection = useCallback(
    <K extends keyof ConnectionInfo>(key: K, value: ConnectionInfo[K]) => {
      setAccountInfo('connection', {
        [key]: value
      })
    },
    [setAccountInfo]
  )

  return (
    <form id="account-info-form">
      <fieldset>
        <legend>表示設定</legend>
        <DateInput
          label="商用利用開始予定年月"
          placeHolder="商用利用開始予定年月を入力してください"
          message="入力が無効です"
          dateFormat="YYYY-MM"
          value={displaySettings.plannedStartDate}
          onChange={(value) => setDisplaySettings('plannedStartDate', value)}
        />
        <TextInput
          label="ニックネーム（第1希望）"
          placeHolder="ニックネームを半角英数字で入力してください"
          message="入力が無効です"
          validator="hankaku_english"
          value={displaySettings.nicknameFirstChoice}
          onChange={(value) => setDisplaySettings('nicknameFirstChoice', value)}
        />
        <TextInput
          label="ニックネーム（第2希望）"
          placeHolder="ニックネームを半角英数字で入力してください"
          message="入力が無効です"
          validator="hankaku_english"
          value={displaySettings.nicknameSecondChoice}
          onChange={(value) => setDisplaySettings('nicknameSecondChoice', value)}
        />
        <TextInput
          label="ニックネーム（第3希望）"
          placeHolder="ニックネームを半角英数字で入力してください"
          message="入力が無効です"
          validator="hankaku_english"
          value={displaySettings.nicknameThirdChoice}
          onChange={(value) => setDisplaySettings('nicknameThirdChoice', value)}
        />
        <TextInput
          label="表示名称（英語）"
          placeHolder="名称を半角英数字で入力してください"
          message="入力が無効です"
          validator="hankaku_english"
          value={displaySettings.titleEn}
          onChange={(value) => setDisplaySettings('titleEn', value)}
        />
        <TextInput
          label="説明文（英語）"
          placeHolder="説明文を半角英数字で入力してください"
          message="入力が無効です"
          rows={5}
          validator="hankaku_english"
          value={displaySettings.descriptionEn}
          onChange={(value) => setDisplaySettings('descriptionEn', value)}
        />
        <TextInput
          label="短い説明文（英語）"
          placeHolder="短い説明文を半角英数字で入力してください"
          message="入力が無効です"
          rows={5}
          validator="hankaku_english"
          value={displaySettings.shortDescriptionEn}
          onChange={(value) => setDisplaySettings('shortDescriptionEn', value)}
        />
        <TextInput
          label="表示名称（日本語）"
          placeHolder="表示名称を入力してください"
          message="入力が無効です"
          validator="non_hankaku_japanese"
          value={displaySettings.titleJa}
          onChange={(value) => setDisplaySettings('titleJa', value)}
        />
        <TextInput
          label="説明文（日本語）"
          placeHolder="説明文を入力してください"
          message="入力が無効です"
          rows={5}
          validator="non_hankaku_japanese"
          value={displaySettings.descriptionJa}
          onChange={(value) => setDisplaySettings('descriptionJa', value)}
        />
        <TextInput
          label="短い説明文（日本語）"
          placeHolder="短い説明文を入力してください"
          message="入力が無効です"
          rows={5}
          validator="non_hankaku_japanese"
          value={displaySettings.shortDescriptionJa}
          onChange={(value) => setDisplaySettings('shortDescriptionJa', value)}
        />
        <TelNoInput
          label="電話番号"
          placeHolder="電話番号を入力してください。例：123-4567-8901"
          message="入力が無効です"
          value={displaySettings.telNo}
          onChange={(value) => setDisplaySettings('telNo', value)}
        />
        <TextInput
          label="住所"
          placeHolder="住所を入力してください"
          message="入力が無効です"
          value={displaySettings.address}
          onChange={(value) => setDisplaySettings('address', value)}
        />
        <NumberInput
          label="住所（緯度）"
          placeHolder="住所（緯度）を入力してください"
          message="入力が無効です"
          min={-90}
          max={90}
          value={displaySettings.addressLat}
          onChange={(value) =>
            setDisplaySettings('addressLat', value || value === 0 ? String(value) : '')
          }
        />
        <NumberInput
          label="住所（経度）"
          placeHolder="住所（経度）を入力してください"
          message="入力が無効です"
          min={-180}
          max={180}
          value={displaySettings.addressLon}
          onChange={(value) =>
            setDisplaySettings('addressLon', value || value === 0 ? String(value) : '')
          }
        />
        <EmailInput
          label="メールアドレス"
          placeHolder="メールアドレスを入力してください"
          message="入力が無効です"
          value={displaySettings.email}
          onChange={(value) => setDisplaySettings('email', value)}
        />
        <TextInput
          label="ウェブサイトURL"
          placeHolder="ウェブサイトURLを半角英数字で入力してください"
          message="入力が無効です"
          validator="url"
          value={displaySettings.websiteURL}
          onChange={(value) => setDisplaySettings('websiteURL', value)}
        />
        <TextInput
          label="ウェルカムメッセージ"
          placeHolder="ウェルカムメッセージを入力してください"
          message="入力が無効です"
          rows={5}
          value={displaySettings.welcomeMessage}
          onChange={(value) => setDisplaySettings('welcomeMessage', value)}
        />
        <TextInput
          label="利用許諾画面URL"
          placeHolder="利用許諾画面URLを入力してください"
          message="入力が無効です"
          validator="url"
          value={displaySettings.licenseURL}
          onChange={(value) => setDisplaySettings('licenseURL', value)}
        />
        <RadioInput
          label="アイコン画像の有無"
          placeHolder="アイコン画像の有無を入力してください"
          message="入力が無効です"
          value={displaySettings.hasIconImage}
          onChange={(value: boolean) => setDisplaySettings('hasIconImage', value)}
        />
        <RadioInput
          label="ウェルカム画像の有無"
          placeHolder="ウェルカム画像の有無を入力してください"
          message="入力が無効です"
          value={displaySettings.hasWelcomeImage}
          onChange={(value: boolean) => setDisplaySettings('hasWelcomeImage', value)}
        />
        <RadioInput
          label="背景画像の有無"
          placeHolder="背景画像の有無を入力してください"
          message="入力が無効です"
          value={displaySettings.hasBackgroundImage}
          onChange={(value: boolean) => setDisplaySettings('hasBackgroundImage', value)}
        />
        <RadioInput
          label="ストア表示の有無"
          placeHolder="ストア表示の有無を入力してください"
          message="入力が無効です"
          value={displaySettings.publish}
          onChange={(value: boolean) => setDisplaySettings('publish', value)}
        />
        <TextsInput
          label="アカウント検索用語"
          placeHolder="アカウント検索用語を入力してください"
          message="入力が無効です"
          values={displaySettings.searchTerms}
          onChange={(values: string[]) => setDisplaySettings('searchTerms', values)}
          readOnly={!displaySettings.publish}
        />
      </fieldset>
      <fieldset>
        <legend>動作設定</legend>
        <RadioInput
          label="ユーザーによるテキスト返信の許可"
          placeHolder="ユーザーによるテキスト返信の許可を決定してください"
          message="入力が無効です"
          value={operationSettings.allowTextReply}
          onChange={(value: boolean) => setOperationSettings('allowTextReply', value)}
        />
        <RadioInput
          label="ユーザーによるファイル返信の許可"
          placeHolder="ユーザーによるファイル返信の許可を決定してください"
          message="入力が無効です"
          value={operationSettings.allowFileTransfer}
          onChange={(value: boolean) => setOperationSettings('allowFileTransfer', value)}
        />
        <RadioInput
          label="ユーザーによる位置情報の許可"
          placeHolder="ユーザーによる位置情報の許可を決定してください"
          message="入力が無効です"
          value={operationSettings.allowGeolocation}
          onChange={(value: boolean) => setOperationSettings('allowGeolocation', value)}
        />
        <RadioInput
          label="アカウントの利用登録の許可"
          placeHolder="アカウントの利用登録の許可を決定してください"
          message="入力が無効です"
          value={operationSettings.allowRegistration}
          onChange={(value: boolean) => setOperationSettings('allowRegistration', value)}
        />
        <TextInput
          label="自動応答エラーメッセージ"
          placeHolder="自動応答エラーメッセージを入力してください"
          message="入力が無効です"
          rows={5}
          validator="non_hankaku_japanese"
          value={operationSettings.autoReplyErrorMessage}
          onChange={(value) => setOperationSettings('autoReplyErrorMessage', value)}
        />
        <StaticMenuInput
          label="固定メニュー"
          values={operationSettings.staticMenu}
          onChange={(values: StaticMenu[]) => setOperationSettings('staticMenu', values)}
        ></StaticMenuInput>
      </fieldset>
      <fieldset>
        <legend>ユースケース</legend>
        <TextInput
          label="ユースケース名"
          placeHolder="ユースケース名を入力してください"
          message="入力が無効です"
          validator="non_hankaku_japanese"
          value={useCase.name}
          onChange={(value) => setUseCase('name', value)}
        />
        <TextInput
          label="利用用途"
          placeHolder="利用用途を入力してください"
          message="入力が無効です"
          rows={5}
          validator="non_hankaku_japanese"
          value={useCase.usage}
          onChange={(value) => setUseCase('usage', value)}
        />
        <TextInput
          label="対象ユーザー"
          placeHolder="対象ユーザーを入力してください"
          message="入力が無効です"
          validator="non_hankaku_japanese"
          value={useCase.targetUser}
          onChange={(value) => setUseCase('targetUser', value)}
        />
        <TextInput
          label="説明"
          placeHolder="説明を入力してください"
          message="入力が無効です"
          rows={5}
          validator="non_hankaku_japanese"
          value={useCase.description}
          onChange={(value) => setUseCase('description', value)}
        />
        <TextInput
          label="想定トラフィック"
          placeHolder="想定トラフィックを入力してください。例：1日2通*200人"
          message="入力が無効です"
          validator="non_hankaku_japanese"
          value={useCase.expectedTraffic}
          onChange={(value) => setUseCase('expectedTraffic', value)}
        />
        <NumberInput
          label="1時間あたりの最大RCS送信数（予定）"
          placeHolder="1時間あたりの最大RCS送信数を入力してください"
          message="入力が無効です"
          value={useCase.plannedMaxRCSTransmissions}
          onChange={(value) => setUseCase('plannedMaxRCSTransmissions', value || 0)}
        />
        {/* <MultiSelectInput
          label="カテゴリー"
          placeHolder="カテゴリーを入力してください"
          message="入力が無効です"
          data={[
            'すべて',
            '車',
            'docomo',
            'ニュース・情報',
            '学ぶ・働く',
            'エンタメ',
            'ヘルス・ビューティ',
            'ブラント・ファッション',
            'ショッピング',
            '旅行・予約',
            'グルメ・フード・レストラン',
            'お金・銀行・電子マネー',
            '公共・ライフライン',
            '交通・宅配'
          ]}
          values={useCase.categories}
          onChange={(value) => setUseCase('categories', value)}
          readOnly={!displaySettings.publish}
        /> */}
      </fieldset>
      <fieldset>
        <legend>接続情報</legend>
        <TextInput
          label="管理画面アクセス元グローバルIP"
          placeHolder="管理画面アクセス元グローバルIPを入力してください"
          message="入力が無効です"
          validator="ip_addresses"
          value={connection.adminAccessIP}
          onChange={(value) => setConnection('adminAccessIP', value)}
        />
        <TextInput
          label="APIアクセス元グローバルIP"
          placeHolder="APIアクセス元グローバルIPを入力してください"
          message="入力が無効です"
          validator="ip_addresses"
          value={connection.apiAccessIP}
          onChange={(value) => setConnection('apiAccessIP', value)}
        />
        <TextInput
          label="webhook URL"
          placeHolder="webhook URLを入力してください"
          message="入力が無効です"
          validator="url"
          value={connection.webhookURL}
          onChange={(value) => setConnection('webhookURL', value)}
        />
      </fieldset>
    </form>
  )
}

function mapStateToProps(state: any) {
  const { accountInfo } = state
  return { accountInfo }
}

export default connect(mapStateToProps, { updateAccountInfo, clearAccountInfo })(AccountInfoForm)
