import React from 'react'
import { connect } from 'react-redux'
import SignInUI from '../../services/sign-in-ui'
import { updateUserInfo } from '../../store/user-info/actions'
import { UserInfo } from './sign-in-types'
interface SignInProps {
  updateUserInfo: (value: UserInfo) => void
}

function SignIn(props: SignInProps) {
  return (
    <div>
      <h1 className="page-header">
        サインイン
      </h1>
      <SignInUI
        signInSuccessUrl="/select-operation"
        updateUserInfo={(userInfo) => {
          props.updateUserInfo(userInfo)
        }}
      />
    </div>
  )
}

export default connect(null, { updateUserInfo })(SignIn)
