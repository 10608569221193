import { combineReducers } from 'redux'
import { userInfoReducer } from './user-info/reducers'
import { companyInfoReducer } from './company-info/reducers'
import { accountInfoReducer } from './account-info/reducers'
import { submissionFilesReducer } from './submission-files/reducers'
import { demoAccountReducer } from './demo-account/reducers'

export const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  companyInfo: companyInfoReducer,
  accountInfo: accountInfoReducer,
  submissionInfo: submissionFilesReducer,
  demoAccount: demoAccountReducer
})

export type RootState = ReturnType<typeof rootReducer>
