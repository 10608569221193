import React from 'react'
import Home from './../pages/Home'
import SignIn from '../ui-components/pages/sign-in'
import Apply from '../ui-components/pages/apply'
import SelectOperation from '../ui-components/pages/select-operation'
import DemoAccount from '../ui-components/pages/demo-account'
import Applications from '../ui-components/pages/applications'

const routes = [
  {
    path: '/',
    exact: true,
    title: 'Home',
    component: () => <Home />
  },
  {
    path: '/sign-in',
    exact: true,
    title: 'Sign In',
    component: () => <SignIn />
  },
  {
    path: '/apply/:operation',
    exact: true,
    title: 'Apply',
    component: () => <Apply />
  },
  {
    path: '/apply/:operation/:appId',
    exact: true,
    title: 'Apply',
    component: () => <Apply />
  },
  {
    path: '/select-operation',
    exact: true,
    title: 'Select Operation',
    component: () => <SelectOperation />
  },
  {
    path: '/demo-account/:operation',
    exact: true,
    title: 'Demo Account',
    component: () => <DemoAccount />
  },
  {
    path: '/applications',
    exact: true,
    title: 'Applications',
    component: () => <Applications />
  },
]

export default routes
